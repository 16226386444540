import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'

// Base Page
import BasePage from '@/pages/Base'

// Services
import OrderServices from '@/services/Orders'
import moment from 'moment'

@Component({})
export default class ManageCommission extends BasePage {
  constructor() {
    super()
  }

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search by Commission for...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'Date',
      align: 'left',
      sortable: true,
      value: 'date'
    },
    {
      text: 'Commission for',
      align: 'left',
      sortable: false,
      value: 'commission_for'
    },
    {
      text: 'Agent/Partner',
      align: 'left',
      sortable: false,
      value: 'agent_or_partner'
    },
    {
      text: 'Order ID Ref',
      align: 'center',
      sortable: false,
      value: 'order_id_ref'
    },
    {
      text: 'Handling Fee',
      align: 'right',
      sortable: false,
      value: 'handling_fee'
    },
    {
      text: 'Commission Amount',
      align: 'right',
      sortable: false,
      value: 'handling_fee'
    },
    {
      text: 'Payout ID',
      align: 'center',
      sortable: false,
      value: 'payout_id'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: '-created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  businessList: any = ['corporation', 'individual', 'partnership', 'empty']
  membershipsList: any = []
  rolesList: any = []
  recomendationList: any = []
  recomendationListSub: any = []
  userDialog: boolean = false
  userDialogData: any = {}

  useAsEdit: boolean = false
  selectedEditId: string | number = null

  countries: any = []
  getCountriesLoading: boolean = false

  filter: any = {
    items: [
      {
        text: 'Agent',
        value: 'agent'
      },
      {
        text: 'Partner',
        value: 'partner'
      }
    ],
    from: {
      selected: null,
      keyword: null
    },
    label: 'Filter by Role',
    hint: 'Apply Filter by Role Here',
    icon: 'mdi-filter-variant',
    loading: false
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getCommission()
  }

  @Watch('filter.from.selected', { deep: true })
  async onFilterChange() {
    console.log('filter', this.filter.from.selected)

    await this.getCommission()
  }

  async onClearSearch() {
    this.searchInput = ''
    await this.getCommission()
  }

  async getCommission() {
    this.tableLoading = true
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        sort: this.tablePagination.descending ? '-created_at' : 'created_at'
      }
    }

    if (this.searchInput != '') {
      opts.params = {
        ...opts.params,
        'filter[name][like]': this.searchInput
      }
    } else {
      var textFilter = 'filter[name][like]'
      delete opts.params[textFilter]
    }

    if (this.filter.from.selected) {
      opts.params = {
        ...opts.params,
        is_commissioned: this.filter.from.selected
      }
    } else {
      opts.params = {
        ...opts.params,
        is_commissioned: 'all'
      }
    }

    try {
      const response = await OrderServices.getOrders(opts)
      const responseData = response.data
      const responseMeta = response.meta

      this.tableItems = []
      forEach(responseData, dataOrder => {

        const order = dataOrder.attributes
        order.commision_page_data.date_format = moment(order.commision_page_data.date).format('DD MMMM YYYY')
        order.commision_page_data.order_id_ref = '#' + order.commision_page_data.order_code
        order.commision_page_data.payout_id = order.commision_page_data.payout_id ? order.commision_page_data.payout_id : '-'
        order.commision_page_data.handling_fee_format = this.formatMoney(order.commision_page_data.handling_fee, order.currency)
        order.commision_page_data.amount_format = this.formatMoney(order.commision_page_data.amount ? order.commision_page_data.amount : 0, order.currency)

        this.tableItems.push(order)
      })

      this.tableTotalItems = responseMeta.pagination.total
      this.tableLoading = false
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }
}
